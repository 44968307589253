<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update user</v-card-title>
    <v-row>
      <v-col cols="6">
        <v-text-field dense outlined label="Name" v-model="item.name"/>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined label="Email" type="email" v-model="item.email"/>
      </v-col>
      <v-col cols="6">
        <v-select v-model="item.role_id" :disabled="!isSuperAdmin" :items="rolesFiltered" item-text="name"
                  item-value="id" label="Role" outlined
                  dense></v-select>
      </v-col>
      <v-col cols="6" v-if="item.role_id === CONSTANTS.ROLES.RECEIVER">
        <v-select v-model="item.additional_data.handle_warehouse_id" :disabled="!isSuperAdmin"
                  :items="warehousesFiltered"
                  item-text="name" item-value="id" label="Warehouse" outlined
                  dense></v-select>
      </v-col>
      <v-col cols="6">
        <v-btn @click="dialogChangePassword = true" color="warning">Change Password</v-btn>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
    <v-dialog v-if="dialogChangePassword" v-model="dialogChangePassword" :width="600">
      <ChangePassword :onCancel="()=>{dialogChangePassword = false}" :userId="item.id" :userChangeIsAdmin="true"
                      :onPasswordChanged="()=>{dialogChangePassword = false}"/>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import ChangePassword from "../Profile/ChangePassword";
import {CONSTANTS} from "@/config";

export default {
  name: 'UpdateUser',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  components: {
    ChangePassword,
  },
  data: () => ({
    item: {
      additional_data: {}
    },
    dialogChangePassword: false,
  }),
  computed: {
    ...mapGetters({
      roles: 'role/all',
      warehouses: 'warehouse/all',
    }),
    rolesFiltered() {
      if (!this.roles) return [];
      if (this.isSuperAdmin) return this.roles;
      return [[this.roles.filter(r => r.name !== 'Super Administrator')]]
    },
    warehousesFiltered() {
      if (!this.warehouses) return [];
      return this.warehouses.filter(r => r.type === CONSTANTS.WAREHOUSE_TYPE.PUBLIC)
    }
  },
  methods: {
    ...mapActions({
      updateItem: 'user/update',
      getAllRoles: 'role/all'
    }),
    processUpdate() {
      this.updateItem(this.item).then(() => {
        this.onUpdated();
      });
    }
  },
  mounted() {
    if (this.roles.length < 1) this.getAllRoles();
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
